import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
    name: 'login',
    initialState: { isLogin: false, user: {}},
    reducers: {
        setLogin(state, action) {
            state.isLogin = true;
        },
        setLogout(state, action) {
           
            state.isLogin = false;
        },
        setUser(state, action) {
            state.user = action.payload;
        }

    },
});

export const loginActions = loginSlice.actions;

export default loginSlice;
